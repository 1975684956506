import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8564 17.0817C16.751 16.857 18.5779 16.4116 20.3107 15.7719C18.8739 14.177 17.9994 12.0656 17.9994 9.75V9.04919C17.9995 9.03281 17.9996 9.01641 17.9996 9C17.9996 5.68629 15.3133 3 11.9996 3C8.68588 3 5.99959 5.68629 5.99959 9L5.99939 9.75C5.99939 12.0656 5.12486 14.177 3.68806 15.7719C5.42099 16.4116 7.24802 16.857 9.14272 17.0818M14.8564 17.0817C13.9196 17.1928 12.9662 17.25 11.9994 17.25C11.0328 17.25 10.0795 17.1929 9.14272 17.0818M14.8564 17.0817C14.9494 17.3711 14.9996 17.6797 14.9996 18C14.9996 19.6569 13.6564 21 11.9996 21C10.3427 21 8.99959 19.6569 8.99959 18C8.99959 17.6797 9.04978 17.3712 9.14272 17.0818M3.12402 7.5C3.4113 5.78764 4.18212 4.23924 5.29127 3M18.7079 3C19.8171 4.23924 20.5879 5.78764 20.8751 7.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
