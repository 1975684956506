import { useEffect, useState } from 'react';
import { Address } from '@Types/account/Address';
import { Money } from '@Types/product/Money';
import { FormProvider, useForm } from 'react-hook-form';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from '../../../../frontastic';
import FormFieldButton from '../../forms/form-field-button';
import FormFieldCheckbox from '../../forms/form-field-checkbox';
import FormFieldInput from '../../forms/form-field-input';
import { CheckoutValidationError } from '../errors/CheckoutValidationError';
import { useCheckout } from '../provider';
import { CartSummaryHandler } from '../utils/CartSummaryHandler';
import { isValidEmail, isValidPhoneNumber, isValidStringField } from '../validation';
import AddressInput from './address-input';
import AddressSelection from './address-selection';
import Disclaimer from './disclaimer';
import OrderSummary from './order-summary';
import PaymentMethods from './payment-methods';

export interface CountryOptionItem {
  display: string;
  data: string;
}

export interface PaymentDescription {
  paymentId: string;
  description: string;
}

export interface Props {
  readonly submitText: string;
  readonly submitForm: () => void;
  readonly isFormValid: boolean;
  readonly isGuestCheckout: boolean;
  readonly addressList?: Address[];
  readonly billingCountryOptions: CountryOptionItem[];
  readonly shippingCountryOptions?: CountryOptionItem[];
  readonly paymentDescriptionList?: PaymentDescription[];
  readonly paymentPreselection?: string;
  readonly deliveryTime?: string;
  readonly paymentB2BDisclaimer?: string;
}

const CheckoutForm = ({
  submitText,
  submitForm,
  isFormValid,
  isGuestCheckout,
  addressList,
  billingCountryOptions,
  shippingCountryOptions,
  paymentDescriptionList,
  paymentPreselection,
  deliveryTime,
  paymentB2BDisclaimer,
}: Props) => {
  const [shippingTotal, setShippingTotal] = useState<Money>(undefined);
  const [shippingInfo, setShippingInfo] = useState<string>('');

  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const { data: cart } = useCart();
  const checkoutData = useCheckout();
  const formMethods = useForm({
    mode: 'onChange',
  });

  const updateAddressDetails = (type: 'shipping' | 'billing' | 'personal', field: string, value: string) => {
    if (typeof value === 'undefined') return;

    if (field === 'addressId') {
      return updateAddressId(type, value);
    }

    if (field === 'country' && !value) {
      value = getCountryValueByOptions(type === 'shipping' ? shippingCountryOptions : billingCountryOptions);
    }

    if (type === 'personal') {
      return updatePersonalAddressDetails(field, value);
    }

    if (type === 'shipping') {
      checkoutData.setShippingAddress({ ...checkoutData.shippingAddress, [field]: value });
      if (checkoutData.billingSameAsShipping && !['firstName', 'lastName'].includes(field)) {
        checkoutData.setBillingAddress({ ...checkoutData.shippingAddress, [field]: value });
      }
      checkoutData.setRecalculationRequired(field === 'country');
    } else {
      checkoutData.setBillingAddress({ ...checkoutData.billingAddress, [field]: value });
    }
  };

  const updatePersonalAddressDetails = (field: string, value: string) => {
    switch (field) {
      case 'firstName':
      case 'lastName':
        checkoutData.setBillingAddress({ ...checkoutData.billingAddress, [field]: value });
        formMethods.setValue(`billing.${field}`, value);

        if (checkoutData.billingSameAsShipping) {
          checkoutData.setShippingAddress({ ...checkoutData.shippingAddress, [field]: value });
          formMethods.setValue(`shipping.${field}`, value);
        }
        break;
      case 'phone':
        // Ensure to keep phone number on billing address
        checkoutData.setShippingAddress({ ...checkoutData.shippingAddress, phone: value });
        if (value?.length > 0) {
          checkoutData.setBillingAddress({ ...checkoutData.billingAddress, phone: value });
        }
      default:
      // nothing for now, we do not have any other "personal" fields ...
    }
  };

  const getCountryValueByOptions = (countryOptions?: CountryOptionItem[]): string | undefined => {
    return countryOptions?.find((option) => option.data === 'DE').data || countryOptions?.[0]?.data;
  };

  const updateAddressId = (type: string, addressId: string) => {
    const { firstName, lastName, phone } = checkoutData.billingAddress;
    const shippingCountry = checkoutData.shippingAddress.country;
    const selectedAddress = addressList?.find((address) => address.addressId === addressId);
    let address = undefined;

    if (selectedAddress) {
      address =
        type === 'billing' || checkoutData.billingSameAsShipping
          ? { ...selectedAddress, firstName, lastName, phone: phone ?? checkoutData.shippingAddress?.phone }
          : { ...selectedAddress, phone: phone ?? checkoutData.shippingAddress?.phone };
    }

    if (type === 'shipping') {
      checkoutData.setShippingAddress(address);
      if (checkoutData.billingSameAsShipping) {
        checkoutData.setBillingAddress(address);
      }
      checkoutData.setRecalculationRequired(shippingCountry !== address.country);
    } else {
      checkoutData.setBillingAddress(address);
    }
  };

  const onSubmit = formMethods.handleSubmit(async (data) => {
    if (isFormValid) {
      await submitForm();
    } else {
      checkoutData.setLastCheckoutError(new CheckoutValidationError('Invalid order data'));
    }
  });

  const preselectedPayment: string | false =
    paymentPreselection
      .split(',')
      .find((paymentId) =>
        checkoutData.paymentMethods.find((paymentMethod) => paymentMethod.paymentMethodId === paymentId),
      ) ?? false;

  useEffect(() => {
    if (typeof preselectedPayment === 'string') {
      checkoutData.setSelectedPaymentMethod(preselectedPayment);
    }
  }, [preselectedPayment]);

  useEffect(() => {
    setShippingTotal(
      CartSummaryHandler.getShippingTotal(
        cart,
        checkoutData.deliveryCountry,
        checkoutData.shippingMethods,
        checkoutData.selectedShippingMethods,
      ),
    );
  }, [cart.sum?.centAmount, cart.shippingAddress, checkoutData.deliveryCountry]);

  useEffect(() => {
    if (shippingTotal?.centAmount !== undefined) {
      setShippingInfo(
        formatCheckoutMessage({
          id: shippingTotal.centAmount > 0 ? 'shippingCostsInfo.default' : 'shippingCostsInfo.free',
          values: {
            country: formatMessage({
              id: `country.${checkoutData.deliveryCountry}`,
              defaultMessage: checkoutData.deliveryCountry,
            }),
            shippingCost: CurrencyHelpers.formatForCurrency(shippingTotal),
          },
        }),
      );
    }
  }, [shippingTotal]);

  return (
    <FormProvider {...formMethods}>
      <div className="lg:flex lg:min-h-full lg:pb-24">
        <OrderSummary displayMobile deliveryTime={deliveryTime} />
        <section aria-labelledby="payment-heading" className="flex-auto pb-16 sm:px-6 sm:pt-16 lg:px-8 lg:py-0">
          <form className="grid grid-cols-12 gap-x-4 gap-y-3" onSubmit={(e) => e.preventDefault()} noValidate>
            <div className="personal-information-header col-span-full pb-1.5 text-xl font-bold">
              {formatCheckoutMessage({ id: 'personalInformation', defaultMessage: '1. personal information' })}
            </div>
            <FormFieldInput
              name="firstName"
              inputAutoComplete="given-name"
              formId="checkout.general"
              value={checkoutData?.shippingAddress?.firstName || checkoutData?.billingAddress?.firstName || ''}
              onChange={(name, value) => updateAddressDetails('personal', name, value)}
              containerClassNames="col-span-6 sm:col-span-6"
              validation={{ required: true, validate: (value) => isValidStringField(value) }}
            />
            <FormFieldInput
              name="lastName"
              inputAutoComplete="family-name"
              formId="checkout.general"
              value={checkoutData?.shippingAddress?.lastName || checkoutData?.billingAddress?.lastName || ''}
              onChange={(name, value) => updateAddressDetails('personal', name, value)}
              containerClassNames="col-span-6 sm:col-span-6"
              validation={{ required: true, validate: (value) => isValidStringField(value) }}
            />
            <FormFieldInput
              name="emailAddress"
              inputAutoComplete="email"
              formId="checkout.general"
              value={checkoutData?.email || ''}
              onChange={(name, value) => typeof value !== 'undefined' && checkoutData.setEmail(value)}
              validation={{
                required: true,
                validate: (value) => isValidEmail(value),
              }}
            />
            <FormFieldInput
              name="phone"
              inputAutoComplete="tel"
              formId="checkout.general"
              smallLabel={formatMessage({
                id: 'phoneHint',
                defaultMessage: 'Necessary to coordinate the delivery date with the forwarding agent',
              })}
              value={checkoutData?.shippingAddress?.phone || checkoutData?.billingAddress?.phone || ''}
              onChange={(name, value) => updateAddressDetails('personal', name, value)}
              validation={{
                required: true,
                minLength: 6,
                validate: (value) => isValidPhoneNumber(value),
              }}
            />
            <div className="personal-information-header col-span-full pt-14 text-xl font-bold">
              {formatCheckoutMessage({ id: 'shippingInformation', defaultMessage: '2. shipping information' })}
            </div>
            {isGuestCheckout ? (
              <AddressInput
                type="shipping"
                address={checkoutData?.shippingAddress}
                countryOptions={shippingCountryOptions || billingCountryOptions}
                showHeader={true}
                updateAddressDetails={updateAddressDetails}
                shippingInfo={shippingInfo}
              />
            ) : (
              <AddressSelection
                type="shipping"
                addressList={addressList}
                showHeader={false}
                showAccountLink={true}
                selected={checkoutData?.shippingAddress?.addressId || undefined}
                updateAddressDetails={updateAddressDetails}
                shippingInfo={shippingInfo}
              />
            )}
            <FormFieldCheckbox
              checked={checkoutData?.billingSameAsShipping}
              onChange={(checked) => {
                if (checked !== undefined) {
                  checkoutData?.setBillingSameAsShipping(checked);
                }
              }}
              name="sameAsShipping"
              label={formatCheckoutMessage({
                id: 'billingAddressSameAsShipping',
                defaultMessage: 'Billing address is the same as shipping address',
              })}
              inverseLabel
              containerClassNames="flex items-center gap-4 col-span-full pt-2"
              labelClassNames="block text-sm text-gray-700 dark:text-light-100"
              validation={{ required: false }}
            />
            {!checkoutData.billingSameAsShipping && (
              <>
                {isGuestCheckout ? (
                  <AddressInput
                    type="billing"
                    address={checkoutData?.billingAddress}
                    countryOptions={billingCountryOptions}
                    showHeader={true}
                    updateAddressDetails={updateAddressDetails}
                  />
                ) : (
                  <AddressSelection
                    type="billing"
                    addressList={addressList}
                    showHeader={false}
                    showAccountLink={true}
                    selected={checkoutData?.billingAddress.addressId || undefined}
                    updateAddressDetails={updateAddressDetails}
                  />
                )}
              </>
            )}

            <PaymentMethods preselected={preselectedPayment || ''} descriptions={paymentDescriptionList} />

            <Disclaimer className="col-span-full pt-6 text-center text-xs" />

            {paymentB2BDisclaimer && (checkoutData.billingAddress.company || checkoutData.shippingAddress.company) && (
              <div className="col-span-full pt-1 text-center text-xs">{paymentB2BDisclaimer}</div>
            )}

            <div className="col-span-full">
              <FormFieldButton buttonText={submitText} onClick={onSubmit} />
            </div>
          </form>
        </section>
        <OrderSummary displayDesktop deliveryTime={deliveryTime} />
      </div>
    </FormProvider>
  );
};

export default CheckoutForm;
