import React from 'react';
import { useRouter } from 'next/router';
import { useFormat } from '../../../../helpers/hooks/useFormat';

export type NotificationErrorProps = {
  status: string;
  title?: string;
  text?: string;
  reason?: string;
};

const NotificationError = ({ status, title, text, reason }: NotificationErrorProps) => {
  const { formatMessage } = useFormat({ name: 'account' });
  const router = useRouter();

  return (
    <main className="flex flex-col lg:flex-row">
      <div className="mx-auto flex-auto py-5 px-4 sm:px-6 lg:py-32 lg:px-8">
        <p className="mt-7 text-4xl font-extrabold tracking-tight text-red-700 sm:text-5xl">
          {title ??
            formatMessage({
              id: `customerService${status[0].toUpperCase() + status.substring(1).toLowerCase()}`,
              defaultMessage: `Status ${status}`,
            })}
        </p>

        {reason && <p className="mt-7 text-base text-gray-500 dark:text-light-100">{reason}</p>}

        <p className="mt-7 text-base text-gray-500 dark:text-light-100">{text}</p>

        <div className="mt-9 border-t border-gray-200 py-6 text-right">
          <p
            className="cursor-pointer text-sm font-medium text-accent-400 hover:text-accent-500"
            onClick={() => router.push('/support')}
          >
            {formatMessage({ id: 'customerService', defaultMessage: 'Customer service' })}
            <span aria-hidden="true"> &rarr;</span>
          </p>
        </div>
      </div>
    </main>
  );
};

export default NotificationError;
