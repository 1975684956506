import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { Category } from '@Types/product/Category';
import { Product } from '@Types/product/Product';
import { DynamicPageSeoProperties } from '@Types/project/DynamicPageSeoProperties';
import { Facet } from '@Types/result/Facet';
import { RangeFacet } from '@Types/result/RangeFacet';
import { Term } from '@Types/result/Term';
import { TermFacet } from '@Types/result/TermFacet';
import Spinner from 'components/commercetools-ui/spinner';
import FilterIcon from 'components/icons/filter';
import Breadcrumb from 'components/revelo-ui/breadcrumb';
import Filters from 'components/revelo-ui/filters';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { useFilter } from '../../../../frontastic';
import Markdown from '../../../revelo-ui/content/markdown';
import Pagination from '../../../revelo-ui/pagination';
import { FilterUtils } from '../../../revelo-ui/utils/FilterUtils';
import List from './list';
import BikeAlarmButton from '../../../revelo-ui/bike-alarm/button';

export interface Props {
  products: Product[];
  previousCursor: string;
  nextCursor: string;
  category: Category;
  facets: Facet[];
  totalProducts: number;
  visibleFilters?: string[];
  noProductsMarkdown?: string;
  categoryTitle?: string;
  debounceDelay?: number;
  seoProperties?: DynamicPageSeoProperties;
}

export default function ProductList({
  products,
  totalProducts,
  previousCursor,
  nextCursor,
  category,
  facets,
  visibleFilters = [],
  noProductsMarkdown = '',
  categoryTitle,
  debounceDelay,
  seoProperties,
}: Props) {
  const [isDesktopSize, width] = useMediaQuery(desktop);
  const router = useRouter();
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const { setFilter, resetFilter, setDebounceDelay, submitFilter, isUpdating } = useFilter();

  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatBikealarmMessage } = useFormat({ name: 'bikealarm' });

  const toggleFiltering = () => {
    if (isDesktopSize) return;

    if (isFiltering) {
      submitFilter();
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    setIsFiltering(!isFiltering);
  };
  const closeFilters = () => setIsFiltering(false);

  useEffect(() => {
    const hasFacetsInQuery = Object.keys(router.query).some((key) => key.includes('facets'));

    if (!hasFacetsInQuery) {
      resetFilter();
    }

    setDebounceDelay(debounceDelay ?? 0);
  }, []);

  const filteredFacets = facets.filter(({ type }) => type === 'term') as TermFacet[];
  filteredFacets.forEach((facet) => {
    const isBooleanFacet =
      (facet.terms.length === 2 &&
        facet.terms.some(({ key }) => key === 'TRUE') &&
        facet.terms.some(({ key }) => key === 'FALSE')) ||
      (facet.terms.length === 1 &&
        (facet.terms.some(({ key }) => key === 'TRUE') || facet.terms.some(({ key }) => key === 'FALSE')));

    if (isBooleanFacet) {
      facet.terms.forEach((term) => {
        // override term label with translation for use in filters and active filters
        term.label = formatProductMessage({
          id: `filter.boolean.${term.key.toLowerCase()}`,
          defaultMessage: term.label,
        });
      });
    } else {
      FilterUtils.sortFacetTerms(facet);
    }
  });

  const activeTermFacets = filteredFacets
    .filter(({ selected }) => selected)
    .map<TermFacet>((facet) => ({
      ...facet,
      terms: facet.terms.filter(({ selected }) => selected),
    }));

  const disableFacetTerm = (facet: TermFacet, term: Term) => {
    setFilter((filter) => ({
      ...filter,
      termFilteringParams: filter.termFilteringParams.filter(
        ({ key, value }) => !(key.includes(`facets[${facet.key}][terms]`) && value === term.label),
      ),
      shouldSubmit: true,
    }));
  };

  const activeRangeFacets = facets.filter(
    ({ type, selected }) => type === 'range' && selected === true,
  ) as RangeFacet[];

  const disableFacetPrice = (facet: RangeFacet) => {
    setFilter((filter) => ({
      ...filter,
      priceFilteringParams: filter.priceFilteringParams.filter(
        ({ key, value }) =>
          !(key.includes(`facets[${facet.key}][min]`) && +value === facet.minSelected) &&
          !(key.includes(`facets[${facet.key}][max]`) && +value === facet.maxSelected),
      ),
      shouldSubmit: true,
    }));
  };

  const totalCountActiveFacets =
    activeTermFacets.reduce((totalCount, facet) => {
      return totalCount + (facet.terms ? facet.terms.length : 0);
    }, 0) + activeRangeFacets.length;

  const clearAll = (
    <NextLink href={router?.asPath.split('?')[0] || ''} key="clear-all">
      <a className="shrink-0 self-center underline lg:pl-4" onClick={resetFilter}>
        {formatProductMessage({ id: 'clear', defaultMessage: 'Clear all' })} ({totalCountActiveFacets})
      </a>
    </NextLink>
  );

  const activeFilters = (activeTermFacets.length > 0 || activeRangeFacets.length > 0) && (
    <div className="align-center mb-4 flex w-full">
      <div className="no-scrollbar flex items-center gap-3 overflow-x-scroll">
        {!isDesktopSize && clearAll}
        {activeTermFacets.length > 0 &&
          activeTermFacets.map((facet) =>
            facet.terms.map((term) => (
              <div
                className="inline-flex h-8 shrink-0 cursor-pointer items-center rounded border border-primary-400 pl-2.5 pr-1.5"
                key={`${facet.key}.${term.key}`}
                onClick={() => disableFacetTerm(facet, term)}
              >
                <span className="text-xs">{term.label}</span>
                <XIcon className="ml-1 inline-block h-3 w-3 text-primary-400" />
              </div>
            )),
          )}
        {activeRangeFacets.length > 0 &&
          activeRangeFacets.map((range) => (
            <div
              className="inline-flex h-8 shrink-0 cursor-pointer items-center rounded border border-primary-400 pl-2.5 pr-1.5"
              key={`${range.key}`}
              onClick={() => disableFacetPrice(range)}
            >
              <span className="text-xs">
                {CurrencyHelpers.formatForCurrency(range.minSelected)} -{' '}
                {CurrencyHelpers.formatForCurrency(range.maxSelected)}
              </span>
              <XIcon className="ml-1 inline-block h-3 w-3 text-primary-400" />
            </div>
          ))}
      </div>
      {isDesktopSize && clearAll}
    </div>
  );

  const bikeAlarmData = {
    btnLabel: formatBikealarmMessage({ id: 'btnLabel', defaultMessage: 'Bike Alarm anlegen' }),
    disclaimer: formatBikealarmMessage({
      id: 'disclaimer',
      defaultMessage:
        '* revelo kann meine Email-Adresse für Marketing-Zweck nutzen und ich habe die <a href="/datenschutz">Datenschutzerklärung</a> zur Kenntnis genommen<br/><br/>** Mit deiner Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO stimmst du auch der Analyse deines Umgangs mit unseren Mailings durch uns zu. Dies geschieht durch Messung, Speicherung und Auswertung von Öffnungsraten und Klickraten zum Zweck der Gestaltung künftiger Mailing-Kampagnen.',
    }),
    emailLabel: formatBikealarmMessage({
      id: 'emailLabel',
      defaultMessage: 'Unter welcher E-Mail Adresse sollen wir dich benachrichtigen? *',
    }),
    headline: formatBikealarmMessage({ id: 'headline', defaultMessage: 'Bike Alarm anlegen' }),
    newsletterLabel: formatBikealarmMessage({
      id: 'newsletterLabel',
      defaultMessage: 'Auch den Newsletter abonnieren **',
    }),
    overviewBtnLabel: formatBikealarmMessage({ id: 'overviewBtnLabel', defaultMessage: 'Benachrichtige mich' }),
    searchLabel: formatBikealarmMessage({ id: 'searchLabel', defaultMessage: 'Benenne deine Suche wie du magst' }),
    subline: formatBikealarmMessage({
      id: 'subline',
      defaultMessage:
        'Filterkriterien kannst du in der Suche anpassen und dann einfach einen Bike Alarm erstellen.<br/>Du kannst so viele Alarme erstellen, wie du magst.',
    }),
  };

  return (
    <div className="mt-4 mb-3 border-b border-gray-200 px-1 sm:px-3 lg:px-6">
      {category?.name && (
        <Head>
          <title>
            {seoProperties?.title ??
              formatMessage({
                id: 'meta.plp.title',
                values: { category: category?.name },
              })}
          </title>
          <meta
            name="description"
            content={
              seoProperties?.description ??
              formatMessage({
                id: 'meta.plp.description',
                values: { category: category?.name },
              })
            }
          />
        </Head>
      )}

      {category && <Breadcrumb breadcrumbs={category.breadcrumbs} />}

      {(categoryTitle || category?.name) && !isDesktopSize && (
        <h1 className="mt-4 text-lg font-bold leading-6 -tracking-[.01em] text-gray-900">
          {categoryTitle || category?.name}
        </h1>
      )}

      {width === 0 ? (
        <div className="flex h-screen items-stretch justify-center py-20 px-12">
          <Spinner />
        </div>
      ) : (
        <>
          {!isDesktopSize && (
            <div className="sticky top-0 z-10 mt-4 gap-16 bg-white">
              <button onClick={toggleFiltering} className="flex w-full justify-between py-2.5">
                <div className="flex gap-1">
                  <FilterIcon className="h-6 w-5 fill-neutral-700 dark:fill-light-100" />
                  <h6 className="text-base font-bold text-neutral-700 dark:text-light-100">
                    {formatProductMessage({ id: 'sortAndFilter', defaultMessage: 'Sort & Filter' })}
                  </h6>
                </div>
                <h6 className="col-span-2 block text-right dark:text-light-100 lg:hidden">
                  {totalProducts}&nbsp;
                  {products.length === 1
                    ? formatProductMessage({ id: 'product', defaultMessage: 'Produkt' })
                    : formatProductMessage({ id: 'products', defaultMessage: 'Produkte' })}
                </h6>
              </button>
            </div>
          )}
          {!isDesktopSize && (
            <div className="gap-16">
              <h6 className="col-span-2 hidden text-right dark:text-light-100 lg:block">
                {totalProducts}&nbsp;
                {products.length === 1
                  ? formatProductMessage({ id: 'product', defaultMessage: 'Produkt' })
                  : formatProductMessage({ id: 'products', defaultMessage: 'Produkte' })}
              </h6>
              {activeFilters}
            </div>
          )}

          {isDesktopSize || isFiltering ? (
            <div className="mt-2 grid gap-8 lg:grid-cols-4">
              <div className="lg:col-span-1">
                <Filters
                  facets={facets}
                  products={products}
                  totalProducts={totalProducts}
                  visibleFilters={visibleFilters}
                  closeFilters={closeFilters}
                />
              </div>
              <div className="lg:col-span-3">
                <div className="align-center mb-4 flex justify-between">
                  {(categoryTitle || category?.name) && isDesktopSize && (
                    <h1 className="text-xl font-semibold leading-6 -tracking-[.01em] text-gray-900">
                      {categoryTitle || category?.name}
                    </h1>
                  )}
                  <h6 className="col-span-2 hidden text-right text-sm text-primary-300 lg:block">
                    {totalProducts}&nbsp;
                    {products.length === 1
                      ? formatProductMessage({ id: 'product', defaultMessage: 'Produkt' })
                      : formatProductMessage({ id: 'products', defaultMessage: 'Produkte' })}
                  </h6>
                </div>
                {isDesktopSize && activeFilters}
                {isUpdating ? (
                  <div className="flex h-screen items-stretch justify-center py-20 px-12">
                    <Spinner />
                  </div>
                ) : totalProducts > 0 && products.length > 0 ? (
                  <>
                    <List products={products} />
                    <Pagination previousCursor={previousCursor} nextCursor={nextCursor} />
                  </>
                ) : (
                  <Markdown className="prose mb-4 text-sm prose-a:text-accent-600" text={noProductsMarkdown} />
                )}
              </div>
            </div>
          ) : totalProducts === 0 ? (
            <Markdown className="prose mb-4 text-sm prose-a:text-accent-600" text={noProductsMarkdown} />
          ) : (
            <>
              <List products={products} />
              <Pagination previousCursor={previousCursor} nextCursor={nextCursor} />
            </>
          )}
          {category && !isDesktopSize && !isFiltering && (
            <BikeAlarmButton
              {...bikeAlarmData}
              activeTermFacets={activeTermFacets}
              activeRangeFacets={activeRangeFacets}
              categoryTitle={categoryTitle || category?.name}
              category={category}
            />
          )}
        </>
      )}
    </div>
  );
}
