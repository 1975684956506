// Doc Examples
//import ContentfulBlog from './doc-examples/contentful/blog';

import AccountDetails from './account/details';
import AccountLogin from './account/login';
import AccountOrdersHistory from './account/orders';
import AccountRegister from './account/register';
import ResetPassword from './account/reset-password';
import AccountTest from './account/test';
import Cart from './cart';
import Checkout from './checkout';
import ThankYou from './checkout/thank-you';
import AccordionTastic from './content/accordion';
import Blog from './content/blog';
import ButtonTastic from './content/button';
import CategoryTeasers from './content/category-teasers';
import ContentSearchTastic from './content/content-search';
import Markdown from './content/markdown';
import ProductGuideTastic from './content/product-guide';
import Spacer from './content/spacer';
import Tile from './content/tile';
import TrustedShopWidgetTastic from './content/trusted-shop-widget';
import ContentSliderTastic from './content/content-slider';
import HelloWorld from './doc-examples/hello-world';
import SimpleButton from './doc-examples/simple-button';
import StarWarsCharacterFilter from './doc-examples/star-wars/character-filter';
import StarWarsCharacterSearch from './doc-examples/star-wars/character-search';
import StarWarsOpeningCrawl from './doc-examples/star-wars/movies';
import Footer from './footer';
import Header from './header';
import Hero from './hero';
import NewsCardTastic from './news-card';
import Newsletter from './newsletter';
import NotFound from './not-found';
import ProductDetails from './products/details';
import ProductList from './products/product-list';
import SimilarProducts from './products/similar-products';
import ProductSlider from './products/slider';
import ReveloThankYou from './revelo-checkout/thank-you';
import AnimatedImageTastic from './revelo-content/animated-image';
import CountdownBannerTastic from './revelo-content/countdown-banner';
import OverlayTastic from './revelo-content/overlay';
import ScriptTastic from './revelo-content/script';
import TileTastic from './revelo-content/tile';
import ReveloFooter from './revelo-footer';
import ReveloHeader from './revelo-header';
import NewsletterTastic from './revelo-newsletter';
import NotificationResultTastic from './revelo-notification/notification-result';
import NotificationOptOutTastic from './revelo-notification/notification-opt-out';
import ProductListWithBannersTastic from './revelo-products/product-list';
import SimilarProductsTastic from './revelo-products/similar-products';
import ProductSliderTastic from './revelo-products/slider';
import Showcase from './showcase';
import UspBar from './usp-bar';
import Wishlist from './wishlist';

export const tastics = {
  // Doc Examples
  'example/simple-button': SimpleButton,
  'example/hello-world': HelloWorld,
  'example/star-wars/movie': StarWarsOpeningCrawl,
  'example/star-wars/character-search': StarWarsCharacterSearch,
  'example/star-wars/character-filter': StarWarsCharacterFilter,
  //'contentful/blog': ContentfulBlog,

  'commercetools/ui/checkout': Checkout,
  'commercetools/ui/thank-you': ThankYou,
  'commercetools/ui/cart': Cart,
  'commercetools/ui/footer': Footer,
  'commercetools/ui/header': Header,
  'commercetools/ui/content/blog': Blog,
  'commercetools/ui/content/tile': Tile,
  'commercetools/ui/content/spacer': Spacer,
  'commercetools/ui/content/showcase': Showcase,
  'commercetools/ui/content/markdown': Markdown,
  'commercetools/ui/content/newsletter': Newsletter,
  'commercetools/ui/products/details': ProductDetails,
  'commercetools/ui/products/product-list': ProductList,
  'commercetools/ui/products/slider': ProductSlider,
  'commercetools/ui/products/similar-products': SimilarProducts,
  'commercetools/ui/wishlist': Wishlist,
  'commercetools/ui/account/test': AccountTest,
  'commercetools/ui/account/details': AccountDetails,
  'commercetools/ui/account/login': AccountLogin,
  'commercetools/ui/account/register': AccountRegister,
  'commercetools/ui/account/orders': AccountOrdersHistory,
  'commercetools/ui/account/reset-password': ResetPassword,
  'commercetools/ui/content/category-teasers': CategoryTeasers,
  'commercetools/ui/content/news-card': NewsCardTastic,
  'commercetools/ui/hero': Hero,

  'revelo/ui/header': ReveloHeader,
  'revelo/ui/footer': ReveloFooter,
  'revelo/ui/thank-you': ReveloThankYou,
  'revelo/ui/content/usp-bar': UspBar,
  'revelo/ui/content/button': ButtonTastic,
  'revelo/ui/content/accordion': AccordionTastic,
  'revelo/ui/content/product-guide': ProductGuideTastic,
  'revelo/ui/content/script': ScriptTastic,
  'revelo/ui/content/tile': TileTastic,
  'revelo/ui/content/countdown-banner': CountdownBannerTastic,
  'revelo/ui/content/animated-image': AnimatedImageTastic,
  'revelo/ui/content/overlay': OverlayTastic,
  'revelo/ui/content/content-search': ContentSearchTastic,
  'revelo/ui/content/content-slider': ContentSliderTastic,
  'revelo/ui/products/slider': ProductSliderTastic,
  'revelo/ui/products/product-list': ProductListWithBannersTastic,
  'revelo/ui/products/similar-products': SimilarProductsTastic,
  'revelo/ui/content/trusted-shop-widget': TrustedShopWidgetTastic,
  'revelo/ui/content/newsletter': NewsletterTastic,
  'revelo/ui/notification/notification-result': NotificationResultTastic,
  'revelo/ui/notification/notification-opt-out': NotificationOptOutTastic,

  default: NotFound,
};
