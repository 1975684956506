import React from 'react';
import ReveloCheckout from 'components/revelo-ui/checkout';
import CheckoutProvider from '../../../components/revelo-ui/checkout/provider';
import { OrderErrorProps } from '../../../components/revelo-ui/order-error';
import { useFormat } from '../../../helpers/hooks/useFormat';

const CheckoutTastic = ({ data }) => {
  const { formatMessage } = useFormat({ name: 'common' });

  const orderErrorData: OrderErrorProps = {
    title: data.orderErrorTitle ?? undefined,
    text: data.orderErrorText ?? undefined,
    image: data.orderErrorImage ?? undefined,
  };

  const getShippingCountries = (data) => {
    return Object.keys(data)
      .filter((key) => key.length === 2 && data[key] === true)
      .map((key) => ({
        data: key,
        display: formatMessage({ id: `country.${key}`, defaultMessage: key }),
      }));
  };

  return (
    <CheckoutProvider>
      <ReveloCheckout
        billingCountryOptions={getShippingCountries(data)}
        shippingCountryOptions={getShippingCountries(data)}
        orderErrorData={orderErrorData}
        loginLink={data.loginLink}
        paymentDescriptionList={data.paymentDescriptionList}
        paymentPreselection={data.paymentPreselection}
        deliveryTime={data.deliveryTimeSnippet}
        paymentB2BDisclaimer={data.paymentB2BDisclaimer}
      />
    </CheckoutProvider>
  );
};

export default CheckoutTastic;
