import React from 'react';
import Head from 'next/head';
import Cart from 'components/revelo-ui/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic/provider';
import CheckoutProvider from '../../../components/revelo-ui/checkout/provider';
import useI18n from '../../../helpers/hooks/useI18n';

const CartTastic = ({ data, pageFolder }) => {
  const { t: translate } = useI18n();
  const { formatMessage } = useFormat({ name: 'cart' });
  const { data: cartList, removeItem, updateItemQuantity } = useCart();
  const editItemQuantity = (lineItemId: string, newQuantity: number) => updateItemQuantity(lineItemId, newQuantity);

  return (
    <CheckoutProvider>
      <Head>
        <title>
          {pageFolder.configuration?.seoTitle
            ? translate(pageFolder.configuration?.seoTitle)
            : formatMessage({
                id: 'checkout',
                defaultMessage: 'checkout',
              })}
        </title>
        <meta
          name="description"
          content={
            pageFolder.configuration?.seoDescription
              ? translate(pageFolder.configuration?.seoDescription)
              : formatMessage({
                  id: 'checkout',
                  defaultMessage: 'checkout',
                })
          }
        />
      </Head>
      <Cart
        cart={cartList}
        removeItem={removeItem}
        editItemQuantity={editItemQuantity}
        pageTitle={data.pageTitle}
        emptyStateImage={data.emptyStateImage}
        emptyStateTitle={data.emptyStateTitle}
        emptyStateSubtitle={data.emptyStateSubtitle}
        emptyStateCTALabel={data.emptyStateCTALabel}
        emptyStateCTALink={data.emptyStateCTALink}
        paymentLogos={data.paymentLogo}
        defaultShippingCountry={data.defaultShippingCountry}
        deliveryTime={data.deliveryTimeSnippet}
      />
    </CheckoutProvider>
  );
};

export default CartTastic;
