import React from 'react';
import Head from 'next/head';
import AccountDetails, { AccountDetailsProps } from 'components/commercetools-ui/account/details';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import { PageFolder, useAccount } from 'frontastic';
import useI18n from '../../../../helpers/hooks/useI18n';

export interface Props {
  data: AccountDetailsProps;
  pageFolder: PageFolder;
}

const AccountDetailsTastic: React.FC<Props> = ({ data, pageFolder }) => {
  const { t: translate } = useI18n();
  //I18n messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  //account data
  const { loggedIn, account } = useAccount();

  //user not logged in
  if (!loggedIn) return <Redirect target={data.loginLink} />;

  return (
    <>
      <Head>
        <title>
          {
            (pageFolder.configuration?.seoTitle ? translate(pageFolder.configuration?.seoTitle) : account.firstName,
            ' ',
            formatAccountMessage({ id: 'account.area', defaultMessage: 'Account area' }))
          }
        </title>
      </Head>
      <AccountDetails {...data} />
    </>
  );
};

export default AccountDetailsTastic;
