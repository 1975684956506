import React, { useEffect, useState } from 'react';
import { useAccount } from '../../../../frontastic';
import { NotificationRequest } from '@Types/account';
import Spinner from '../../../commercetools-ui/spinner';
import CheckCircleIcon from '../../../icons/check-circle';
import CheckCrossIcon from '../../../icons/check-cross';
import { useFormat } from '../../../../helpers/hooks/useFormat';

const NotificationOptOut = ({ notification }) => {
  const { updateNotification } = useAccount();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorCode, setErrorCode] = useState<number>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(undefined);
  const { formatMessage: formatBikealarmMessage } = useFormat({ name: 'bikealarm' });

  useEffect(() => {
    const callApi = async () => {
      const result = await updateNotification({
        id: notification.notificationId,
        accountId: notification.accountId,
        active: false,
      } as NotificationRequest);

      setSuccess(result.success);
      setErrorCode(result.errorCode);

      if (result.success === false) {
        setErrorMessage(result.errorMessage);
      }
    };

    callApi();

    setLoading(false);
  }, []);

  return (
    <div className="relative mx-auto max-w-lg">
      <div className="mt-8 mb-6 text-center text-2xl font-bold text-neutral-900">
        {formatBikealarmMessage({ id: 'optOut.headline', defaultMessage: 'Bike Alarm Deaktivierung' })}
      </div>
      {loading && (
        <div className="absolute flex h-full w-full items-stretch justify-center bg-white/50 py-10 px-12">
          <Spinner />
        </div>
      )}
      {success && (
        <div className="flex flex-col gap-8">
          <div className="mb-8 flex items-center gap-3 rounded-lg border-2 border-blue-600 bg-blue-300 py-2.5 px-4">
            <CheckCircleIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
            <div className="text-sm text-blue-600">
              {formatBikealarmMessage({
                id: 'optOut.successMessage',
                defaultMessage: 'Dein Bike Alarm {title} wurde erfolgreich deaktiviert!',
                values: {
                  title: notification.title,
                },
              })}
            </div>
          </div>
        </div>
      )}
      {errorCode !== null && success === false && (
        <div className="mb-8 flex items-center gap-3 rounded-lg border-2 border-error-400 bg-error-200 py-2.5 px-4">
          <CheckCrossIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
          <div className="text-sm text-error-400">
            <p>{formatBikealarmMessage({ id: 'optOut.errorMessage', defaultMessage: 'Etwas ist schief gelaufen.' })}</p>
            {errorMessage && <p className="mt-2">{errorMessage}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationOptOut;
