import React, { useState } from 'react';
import EnvelopeIcon from '../../../icons/envelope';
import { useForm, FormProvider } from 'react-hook-form';
import FormFieldInput from '../../forms/form-field-input';
import FormFieldCheckbox from '../../forms/form-field-checkbox';
import styles from '../bike-alarm.module.scss';
import CheckCrossIcon from '../../../icons/check-cross';
import CheckCircleIcon from '../../../icons/check-circle';
import { TermFacet } from '@Types/result/TermFacet';
import { RangeFacet } from '@Types/result/RangeFacet';
import { useAccount } from '../../../../frontastic';
import { CurrencyHelpers } from '../../../../helpers/currencyHelpers';
import { Category } from '@Types/product/Category';
import Spinner from '../../../commercetools-ui/spinner';
import { NotificationRequest } from '@Types/account';
import { useFormat } from '../../../../helpers/hooks/useFormat';

export interface Props {
  headline: string;
  subline: string;
  searchLabel: string;
  emailLabel: string;
  disclaimer: string;
  newsletterLabel: string;
  btnLabel: string;
  filteredTermFacets: TermFacet[];
  filteredRangeFacets: RangeFacet[];
  categoryTitle: string;
  category: Category;
}

const BikeAlarmOverview: React.FC<Props> = ({
  headline,
  subline,
  searchLabel,
  emailLabel,
  disclaimer,
  newsletterLabel,
  btnLabel,
  filteredTermFacets,
  filteredRangeFacets,
  categoryTitle,
  category,
}) => {
  const { account, loggedIn } = useAccount();
  const prefilledTitle = `${categoryTitle} ${filteredTermFacets
    .flatMap((facet) => facet.terms.map((term) => term.label))
    .slice(0, 2)
    .join(', ')}`;
  const [data, setData] = useState({
    category: category,
    searchTitle: categoryTitle ? prefilledTitle : '',
    selectedFilterValues: filteredTermFacets,
    selectedRangeFilterValues: filteredRangeFacets,
    email: loggedIn ? account.email : '',
    newsletter: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<number>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(undefined);

  const { formatMessage } = useFormat({ name: 'bikealarm' });

  const formMethods = useForm({
    mode: 'onChange',
  });
  const { addNotification } = useAccount();

  const updateSearchParams = (field: string, value: string) => {
    setData({ ...data, [field]: value });
  };

  const activeFilters = filteredTermFacets.length > 0 && (
    <div>
      {filteredTermFacets.map((facet) => (
        <div key={facet.key} className="mb-4 last:mb-0">
          <div className="mb-2 text-sm">{facet.label}</div>
          <div className="flex gap-1 overflow-auto md:flex-wrap">
            {facet.terms.map((term) => (
              <span
                key={`${facet.key}.${term.key}`}
                className="rounded border border-primary-100 px-2.5 py-1.5 text-xs"
              >
                {term.label}
              </span>
            ))}
          </div>
        </div>
      ))}
      {filteredRangeFacets.map((range) => (
        <div key={range.key} className="mb-4 last:mb-0">
          <div className="mb-2 text-sm">Preis</div>
          <div className="flex gap-1 overflow-auto md:flex-wrap">
            <span className="rounded border border-primary-100 px-2.5 py-1.5 text-xs">
              {CurrencyHelpers.formatForCurrency(range.minSelected)} -{' '}
              {CurrencyHelpers.formatForCurrency(range.maxSelected)}
            </span>
          </div>
        </div>
      ))}
    </div>
  );

  const onSubmit = async (formData) => {
    const { selectedFilterValues, selectedRangeFilterValues, email, newsletter, searchTitle, category } = data;
    const termFilters = selectedFilterValues.map(({ identifier, type, terms }) => ({
      filterId: identifier,
      type,
      values: terms.map((term) => term.identifier),
    }));

    const rangeFilters = selectedRangeFilterValues.map(({ identifier, type, min, minSelected, max, maxSelected }) => ({
      filterId: identifier,
      type,
      min,
      minSelected,
      max,
      maxSelected,
    }));

    const notification: NotificationRequest = {
      email,
      newsletter,
      notification: {
        title: searchTitle,
        category: {
          categoryId: category.categoryId,
          slug: category.slug,
        },
        filters: [...termFilters, ...rangeFilters],
      },
    };

    setLoading(true);
    const result = await addNotification(notification);

    setSuccess(result.success);
    setErrorCode(result.errorCode);

    if (result.success === false) {
      setErrorMessage(result.errorMessage);
    }
    setLoading(false);
  };

  const onError = (e) => {
    setErrorCode(e.errorCode);
    setSuccess(false);
  };

  return (
    <div className={styles.formWrapper}>
      {errorCode === null && !success && (
        <FormProvider {...formMethods}>
          <div className="mt-6 text-center text-2xl font-bold">{headline}</div>
          <div
            className="my-8 text-sm text-neutral-900"
            dangerouslySetInnerHTML={{
              __html: subline,
            }}
          ></div>
          {loading && (
            <div className="absolute flex h-full w-full items-stretch justify-center bg-white/50 py-10 px-12">
              <Spinner />
            </div>
          )}
          <form onSubmit={formMethods.handleSubmit(onSubmit, onError)}>
            <div className="flex flex-col gap-8">
              <div className="flex-auto">
                <FormFieldInput
                  name="searchTitle"
                  inputType="text"
                  value={data.searchTitle || ''}
                  onChange={(name, value) => updateSearchParams(name, value)}
                  containerClassNames="col-span-6 sm:col-span-6"
                  inputClassNames="block w-full rounded-lg border-2 border-gray-300 text-sm px-4 py-3 focus:border-primary-400 focus:ring-primary-400 sm:text-sm"
                  labelClassNames="mb-1 block text-xs font-normal text-neutral-400 dark:text-light-100"
                  label={searchLabel}
                  placeholder="Titel"
                  validation={{ required: true, maxLength: 64 }}
                />
              </div>
              {activeFilters}
              <div className={`flex flex-wrap gap-4 ${styles.disclaimerWrapper}`}>
                <div className="flex-auto">
                  <FormFieldInput
                    name="email"
                    inputType="email"
                    inputAutoComplete="email"
                    value={data.email || ''}
                    onChange={(name, value) => updateSearchParams(name, value)}
                    validation={{ required: true }}
                    containerClassNames="col-span-6 sm:col-span-6"
                    inputClassNames="block w-full rounded-lg border-2 text-sm border-gray-300 px-4 py-3 focus:border-primary-400 focus:ring-primary-400 sm:text-sm read-only:text-primary-100 read-only:!border-primary-100 read-only:!shadow-none read-only:!ring-transparent"
                    labelClassNames="mb-1 block text-xs font-normal text-neutral-400 dark:text-light-100"
                    label={emailLabel}
                    placeholder="E-Mail"
                    isReadOnly={loggedIn}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <FormFieldCheckbox
                    checked={data.newsletter}
                    onChange={(checked) => {
                      if (checked !== undefined) {
                        setData({ ...data, newsletter: checked });
                      }
                    }}
                    name="newsletter"
                    label={newsletterLabel}
                    inverseLabel
                    containerClassNames="flex items-center gap-2"
                    inputClassNames="h-5 w-5 rounded-lg border-primary-100 text-transparent"
                    labelClassNames="text-xs font-light"
                    validation={{ required: false }}
                  />
                </div>
                <div
                  className="font-neutral-900 text-xs font-light"
                  dangerouslySetInnerHTML={{
                    __html: disclaimer,
                  }}
                ></div>
              </div>
            </div>
            <button className="mt-8 flex w-full items-center justify-center gap-2 rounded-md bg-accent-200 px-4 py-3">
              <EnvelopeIcon className="h-6 w-6 text-primary-100" aria-hidden="true" />
              <div className="text-sm text-font-light">{btnLabel}</div>
            </button>
          </form>
        </FormProvider>
      )}
      {success && (
        <div>
          <div className="flex flex-col gap-8">
            <div className="pt-6 text-center text-2xl font-bold text-neutral-900">
              {formatMessage({ id: 'successHeadline', defaultMessage: 'Last Step!' })}
            </div>
            <div className="mb-8 flex items-center gap-3 rounded-lg border-2 border-blue-600 bg-blue-300 py-2.5 px-4">
              <CheckCircleIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
              <div className="text-sm text-blue-600">
                {formatMessage({ id: 'successMessage', defaultMessage: 'Die Email wurde erfolgreich verschickt.' })}
              </div>
            </div>
          </div>
        </div>
      )}
      {errorCode !== null && success === false && (
        <div>
          <div className="flex flex-col gap-8">
            <div className="pt-6 text-center text-2xl font-bold text-neutral-900">
              {formatMessage({ id: 'errorHeadline', defaultMessage: 'Oh no!' })}
            </div>
            <div className="mb-8 flex items-center gap-3 rounded-lg border-2 border-error-400 bg-error-200 py-2.5 px-4">
              <CheckCrossIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
              <div className="text-sm text-error-400">
                <p>
                  {formatMessage({
                    id: 'errorMessage',
                    defaultMessage:
                      'Deine Suche konnten wir leider nicht anlegen. Bitte versuche es nochmal oder kontaktiere unseren Customer Support.',
                  })}
                </p>
                {errorMessage && <p className="mt-2">{errorMessage}</p>}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BikeAlarmOverview;
