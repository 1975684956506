import ProductList from 'components/revelo-ui/products/product-list';
import useI18n from '../../../../helpers/hooks/useI18n';

function ProductListWithBannersTastic({ data, pageFolder }) {
  const { t: translate } = useI18n();
  if (!data?.data?.dataSource) return <></>;

  const { items, facets, category, previousCursor, nextCursor, total, additionalDetails } = data.data.dataSource;
  const visibleFilters = data.visibleFilters ? data.visibleFilters.split(',') : [];
  const debounceDelay = data.debounceDelay ?? 1000;
  const noProductsMarkdown = data.noProductsMarkdown;
  const categoryTitle = data.categoryTitle;
  const banners = data.fieldGroup;

  const seoProperties = {
    title: pageFolder.configuration?.seoTitle && translate(pageFolder.configuration?.seoTitle),
    description: pageFolder.configuration?.seoDescription && translate(pageFolder.configuration?.seoDescription),
    keywords: pageFolder.configuration?.seoKeywords && translate(pageFolder.configuration?.seoKeywords),
  };

  return (
    <ProductList
      products={items}
      totalProducts={total}
      facets={facets}
      category={category}
      previousCursor={previousCursor}
      nextCursor={nextCursor}
      visibleFilters={visibleFilters}
      noProductsMarkdown={noProductsMarkdown}
      categoryTitle={categoryTitle}
      banners={banners}
      notification={additionalDetails?.notification}
      debounceDelay={debounceDelay}
      seoProperties={seoProperties}
    />
  );
}

export default ProductListWithBannersTastic;
