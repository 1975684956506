import React from 'react';
import Footer from '../../../components/revelo-ui/footer';

const ReveloFooterTastic = ({ data }) => {
  return (
    <div className="fixed-screen-width lg:relative-width">
      <Footer
        columns={data.columns}
        copyright={data.copyright}
        copyrightLinks={data.copyrightLinks}
        enableMobileTrustBadge={data.mobileTsBadge}
      />
    </div>
  );
};

export default ReveloFooterTastic;
