import React from 'react';
import NotificationOptOut from 'components/revelo-ui/notification/notification-opt-out';
import { Notification, NotificationCriteria } from '@Types/notification';
import { Result } from '@Types/product/Result';

type NotificationResultProps = {
  data: {
    data?: {
      dataSource?: NotificationListDataSource | NotificationDataSource;
    };
    snippets?: Snippet[];
    visibleFilters?: string;
    debounceDelay?: number;
  };
};

type NotificationListDataSource = {
  email?: string;
  notifications: Notification[];
};

type NotificationDataSource = {
  notificationId: string;
  title?: string;
  status?: string;
  criteria?: NotificationCriteria;
  products?: Result;
  reason?: string;
  snippets?: Record<string, string>;
};

type Snippet = {
  snippetId: string;
  snippetText: string;
};

const NotificationOptOutTastic = ({ data }) => {
  const { accountId, notificationId, title, criteria, status } = data.data?.dataSource;

  return <NotificationOptOut notification={{ accountId, notificationId, title, criteria }} />;
};

export default NotificationOptOutTastic;
