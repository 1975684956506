import { NotificationCriteria, NotificationResult, RangeFilter, TermFilter } from '@Types/notification';
import { Facet } from '@Types/result/Facet';
import { RangeFacet } from '@Types/result/RangeFacet';
import { TermFacet } from '@Types/result/TermFacet';
import { URLParam } from '../../../helpers/utils/updateURLParams';
import { FilterUtils } from './FilterUtils';

export class NotificationUtils {
  public static isTermFilter(filter: any): filter is TermFilter {
    return (
      (filter as TermFilter).filterId !== undefined &&
      (filter as TermFilter).values !== undefined &&
      Array.isArray(filter.values)
    );
  }

  public static isRangeFilter(filter: any): filter is RangeFilter {
    const min = (filter as RangeFilter).min ?? (filter as RangeFilter).minSelected;
    const max = (filter as RangeFilter).max ?? (filter as RangeFilter).maxSelected;

    return (filter as RangeFilter).filterId !== undefined && min !== undefined && max !== undefined;
  }

  public static getNotificationUrlParams(notification: NotificationResult, supported: Facet[]): URLParam[] {
    const urlParams: URLParam[] = [];

    if (notification.criteria.filters.length > 0) {
      urlParams.push(
        ...FilterUtils.getFilteringParams(NotificationUtils.getNotificationFacets(notification.criteria, supported)),
      );
    }

    if (notification.criteria.sortBy) {
      const sortBy = notification.criteria.sortBy.split(' ');
      urlParams.push({
        key: `sortAttributes[0][${sortBy[0]}]`,
        value: sortBy[1] ?? 'desc',
      });
    }

    return urlParams;
  }

  public static getNotificationFacets(criteria: NotificationCriteria, supported: Facet[]): Facet[] {
    const facets: Facet[] = [];
    for (const filter of criteria.filters) {
      const baseFacet = supported.find(
        (facet) =>
          (facet.identifier === filter.filterId || facet.key === filter.filterId) && facet.type === filter.type,
      );

      if (baseFacet !== undefined) {
        if (NotificationUtils.isTermFilter(filter)) {
          facets.push({
            ...baseFacet,
            selected: true,
            terms: filter.values.map((term) => ({
              identifier: term,
              label: term,
              selected: true,
            })),
          } as TermFacet);
        } else if (NotificationUtils.isRangeFilter(filter)) {
          facets.push({
            ...baseFacet,
            selected: true,
            minSelected: filter.minSelected ?? filter.min,
            maxSelected: filter.maxSelected ?? filter.max,
          } as RangeFacet);
        }
      }
    }

    return facets;
  }
}
