import React from 'react';
import { Notification, NotificationCriteria } from '@Types/notification';
import { Result } from '@Types/product/Result';
import NotificationError from 'components/revelo-ui/notification/notification-error';
import NotificationList from 'components/revelo-ui/notification/notification-list';
import NotificationResult from 'components/revelo-ui/notification/notification-result';

type NotificationResultProps = {
  data: {
    data?: {
      dataSource?: NotificationListDataSource | NotificationDataSource;
    };
    snippets?: Snippet[];
    visibleFilters?: string;
    debounceDelay?: number;
  };
};

type NotificationListDataSource = {
  email?: string;
  notifications: Notification[];
};

type NotificationDataSource = {
  notificationId: string;
  title?: string;
  status?: string;
  criteria?: NotificationCriteria;
  products?: Result;
  reason?: string;
  snippets?: Record<string, string>;
};

type Snippet = {
  snippetId: string;
  snippetText: string;
};

function isNotificationListSource(source: any): source is NotificationListDataSource {
  return (source as NotificationListDataSource).notifications !== undefined;
}

const NotificationResultTastic: React.FC<NotificationResultProps> = ({ data }) => {
  if (isNotificationListSource(data.data?.dataSource)) {
    const { email, notifications } = data.data.dataSource;
    return <NotificationList notifications={{ email, notifications }} />;
  }

  const { notificationId, title, criteria, status, products, reason, snippets } = data.data?.dataSource;
  const snippetList = snippets ?? {};

  if (data.snippets?.length > 0) {
    for (const snippet of data.snippets) {
      snippetList[snippet.snippetId] = snippet.snippetText;
    }
  }

  if (status !== 'ok') {
    return (
      <NotificationError
        status={status}
        title={snippetList.errorTitle}
        text={snippetList.errorMessage}
        reason={reason}
      />
    );
  }

  const accountId = '';

  return (
    <NotificationResult
      notification={{ accountId, notificationId, title, criteria, products, snippets: snippetList }}
      visibleFilters={data.visibleFilters}
      debounceDelay={1000}
    />
  );
};

export default NotificationResultTastic;
